import * as types from '../../actions/types';

const sendFundsReducer = (
    state = {
        pagination: { items: [] },
        loading: false,
        refetchData: false,
        loadingDataTable: false,
        error: '',
    },
    action = {}
) => {
    switch (action.type) {
        case types.REFETCH_DATA:
            return { ...state, refetchData: true };
        case types.CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case types.SEND_FUNDS_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                loadingDataTable: true,
                refetchData: false,
                error: '',
            };
        case types.SEND_FUNDS_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingDataTable: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case types.SEND_FUNDS_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                loadingDataTable: false,
                error: action.payload,
            };
        case types.SEND_FUNDS_CREATE_NEW_FUND_REQUEST:
            return { ...state, loading: true };
        case types.SEND_FUNDS_CREATE_NEW_FUND_SUCCESS:
        case types.SEND_FUNDS_CREATE_NEW_FUND_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default sendFundsReducer;
